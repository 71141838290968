<template>
  <div id="messages">
    <div v-if="messages.length > 0">
      <div v-for="message in messages" :key="message.id">
        <b-card class="my-2" :class="getClassForMessage(message)">
          <b-card-title style="font-size: medium">
            <b-icon-chat-right class="mr-2"/> <strong>{{ message.createdBy.label }}</strong>
          </b-card-title>
          <b-card-sub-title class="small mb-2">
            {{ formatDate(message.createdAt) }}
          </b-card-sub-title>
          <b-card-text>{{ message.text }}</b-card-text>
        </b-card>
      </div>
    </div>
    <div v-else>
      <b-alert variant="info" show class="m-2">
        <b-icon-info-circle/> {{ $t('comp.messages.noMessages.label') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Messages',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
    messages: {
      type: Array,
      default () {
        return []
      }
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    getClassForMessage (message) {
      if (this.view === 'user') {
        return message.view === 'user' ? 'messageRightAligned' : 'messageLeftAligned'
      } else {
        return message.view === 'admin' || message.view === 'support' || message.view === 'advisor' || message.view === 'manager' ? 'messageRightAligned' : 'messageLeftAligned'
      }
    },
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
.messageRightAligned {
  width: 65%;
  text-align: right;
  float: right;
}
.messageLeftAligned {
  width: 65%;
  text-align: left;
  float: left;
}
</style>
