<template>
  <div id="requestInfo">
    <b-card class="mb-3">
      <template #header>
        <b-icon-info-circle/> {{ $t('comp.requestinfo.details.label') }}
      </template>
      <ul>
        <li><strong>{{ $t('comp.requestinfo.createdAt.label') }}:</strong> {{ formatDate(request.createdAt) }}</li>
        <li><strong>{{ $t('comp.requestinfo.forProject.label') }}:</strong> {{ request.forProject.label }}</li>
        <li><strong>{{ $t('comp.requestinfo.state.label') }}:</strong> {{ $t(`states.${request.state}.label`) }}</li>
        <li v-if="request.account"><strong>{{ $t('comp.requestinfo.account.label') }}:</strong> {{ request.account }}</li>
      </ul>
    </b-card>
    <b-card class="mb-3">
      <template #header>
        <b-icon-envelope/> {{ $t('comp.requestinfo.messages.label') }}
      </template>
      <b-card-text>
        <Messages :messages="request.messages" :view="view"/>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import Messages from '@/components/generic/Messages'

export default {
  name: 'RequestInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Messages
  },
  props: {
    request: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
</style>
