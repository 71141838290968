<template>
  <div id="vouchersTab">
    <div v-if="vouchers.length > 0">
      <div class="accordion" role="tablist">
        <b-card v-for="voucher in vouchers" :key="voucher.voucherId" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`voucher-${voucher.voucherId}`" variant="light" class="text-left">
              <b-icon-file-earmark-text/> {{ $t('comp.voucherstab.voucherForProject.label', {
              code: voucher.forCode,
              project: voucher.forProject.key
            }) }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`voucher-${voucher.voucherId}`" accordion="vouchers" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <Voucher :voucher="voucher" view="manager" :is-archive="isArchive"/>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div v-else-if="vouchers.length === 0 && filtered">
      <b-alert variant="info" show class="mx-3">
        <b-icon-info-circle/> {{ $t('comp.voucherstab.noManagerVouchersFound.description') }}
      </b-alert>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">
        <b-icon-info-circle/> {{ $t('comp.voucherstab.noManagerVoucher.description') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Voucher from '@/components/manager/Voucher'

export default {
  name: 'VouchersTab',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Voucher
  },
  props: {
    vouchers: {
      type: Array,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    isArchive: {
      type: Boolean,
      default: false
    },
    filtered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
