<template>
  <div id="accountForm">
    <b-card class="mx-2 mx-md-5 mb-3" :header="cardHeader">
      <b-form-group :label="$t('comp.accountform.username.label')"
                    :description="$t('comp.accountform.username.description')" >
        <b-form-input v-model="account.form.username" required disabled/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.homeDirectory.label')"
                    :description="$t('comp.accountform.homeDirectory.description')">
        <b-form-input v-model="account.form.homeDirectory" disabled/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.uid.label')"
                    :description="$t('comp.accountform.uid.description')">
        <b-form-input v-model="account.form.uid" disabled/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.state.label')" label-for="state"
                    :description="$t('comp.accountform.state.description')">
        <StateSelect :selected-state.sync="account.form.state" entity="account" :view="view"/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.project.label')" label-for="project"
                    :description="$t('comp.accountform.project.description')">
        <ProjectSelect :selected-project.sync="account.form.project" :is-disabled="true" :loadValidity="false"
                       :view="view"/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.otherprojects.label')" label-for="otherprojects"
                    :description="$t('comp.accountform.otherprojects.description')">
        <ProjectSelect :selected-projects.sync="account.form.otherProjects" :is-disabled="true" :loadValidity="false"
                       :view="view"/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.user.label')" label-for="user"
                    :description="$t('comp.accountform.user.description')">
        <UserSelect :selected-user.sync="account.form.user" :is-disabled="true" :view="view"/>
      </b-form-group>
      <b-form-group :label="$t('comp.accountform.validUntil.label')" label-for="valid-until"
                      :description="$t('comp.accountform.validUntil.description')">
        <b-input-group v-if="action === 'edit'" class="d-flex flex-nowrap">
          <b-input-group-prepend class="pb-2" is-text v-b-hover="handleDateSyncHover"
                                 :title="isDateSyncOk ? $t('comp.accountform.syncdateok.title') : $t('comp.accountform.syncdate.title')"
                                 role="button" @click="syncDate()">
            <b-iconstack v-if="isDateSyncHovered && !isDateSyncOk">
              <b-icon stacked icon="circle-fill"></b-icon>
              <b-icon stacked icon="arrow-repeat" rotate="135" scale="0.75" variant="light"></b-icon>
            </b-iconstack>
            <b-iconstack v-if="!isDateSyncHovered && !isDateSyncOk">
              <b-icon stacked icon="circle"></b-icon>
              <b-icon stacked icon="arrow-repeat" rotate="135" scale="0.75"></b-icon>
            </b-iconstack>
            <b-icon v-if="isDateSyncHovered && isDateSyncOk" icon="check-circle-fill" ></b-icon>
            <b-icon v-if="!isDateSyncHovered && isDateSyncOk" icon="check-circle" ></b-icon>
          </b-input-group-prepend>
          <b-form-datepicker no-flip id="valid-until" :showDecadeNav="account.showDateTimeDecadeNav" v-model="account.form.validUntil"
                             v-bind="datePickerLabels || {}" class="mb-2" :value-as-date="true" @shown="isDateSyncOk = false"/>
        </b-input-group>
        <b-form-datepicker v-else no-flip id="valid-until" :showDecadeNav="account.showDateTimeDecadeNav" v-model="account.form.validUntil"
                             v-bind="datePickerLabels || {}" class="mb-2" :value-as-date="true"/>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import StateSelect from '@/components/generic/select/StateSelect'
import ProjectSelect from '@/components/generic/select/ProjectSelect'
import UserSelect from '@/components/generic/select/UserSelect'

export default {
  name: 'AccountForm',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin],
  components: {
    StateSelect,
    ProjectSelect,
    UserSelect
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isDateSyncHovered: false,
      isDateSyncOk: false
    }
  },
  computed: {
    cardHeader () {
      if (this.action === 'add') {
        return this.$i18n.t('comp.accountform.add.label')
      } else if (this.action === 'edit') {
        return this.$i18n.t('comp.accountform.edit.label')
      } else {
        return ''
      }
    }
  },
  methods: {
    handleDateSyncHover (hovered) {
      this.isDateSyncHovered = hovered
    },
    syncDate () {
      if (this?.account?.form?.project) {
        this.$store.dispatch({ type: 'manager/getValidity', projectId: this.account.form.project.key }).then(response => {
          if (response?.validUntil) {
            this.account.form.validUntil = new Date(response.validUntil) // Format to JSDate for display
            this.isDateSyncOk = true
          } else {
            console.log('No date returned!')
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        console.log('No project to sync date with!')
      }
    }
  }
}
</script>

<style scoped>
</style>
