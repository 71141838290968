<template>
  <div id="projects">
    <div class="row justify-content-md-start mx-3 mb-3">
      <div class="col-6 col-md-3 pl-0 pr-2">
        <b-form-input class="w-100" v-model="projectFilter" type="search" placeholder="Filter ..."/>
      </div>
      <div class="col-6 col-md-auto pl-2 pr-0">
        <b-button class="w-100" @click="reloadProjects()" variant="primary">
          <b-icon-arrow-repeat/> {{ $t('comp.projects.refreshButton.label') }}
        </b-button>
      </div>
    </div>
    <div v-if="projects.length > 0">
      <div class="accordion" role="tablist">
        <b-card v-for="project in projects" :key="project.projectId" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`project-${project.projectId}`" variant="light" class="text-left">
              <b-icon-play-circle-fill v-if="project.state === 'active'" :id="`popover-${project.projectId}-active`" variant="success" class="mr-1"/>
              <b-popover v-if="project.state === 'active'" :target="`popover-${project.projectId}-active`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.projects.active.title') }}</template>
                {{ $t('comp.projects.active.text') }}
              </b-popover>
              <b-icon-stop-circle-fill v-if="project.state === 'inactive' || project.state === 'expired' || project.state === 'withdrawn'" :id="`popover-${project.projectId}-inactive`" variant="dark" class="mr-1"/>
              <b-popover v-if="project.state === 'inactive'" :target="`popover-${project.projectId}-inactive`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.projects.inactive.title') }}</template>
                {{ $t('comp.projects.inactive.text') }}
              </b-popover>
              <b-icon-check-circle-fill v-if="project.state === 'completed'" :id="`popover-${project.projectId}-completed`" variant="info" class="mr-1"/>
              <b-popover v-if="project.state === 'completed'" :target="`popover-${project.projectId}-completed`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.projects.completed.title') }}</template>
                {{ $t('comp.projects.completed.text') }}
              </b-popover>
              <b-icon-info-circle-fill v-if="project.willExpire && project.state === 'active'" :id="`popover-${project.projectId}-expiry`" variant="warning" class="mr-1"/>
              <b-popover v-if="project.willExpire && project.state === 'active'" :target="`popover-${project.projectId}-expiry`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.projects.expiryicon.title') }}</template>
                {{ $t('comp.projects.expiryicon.text') }}
              </b-popover>
              <b-icon-exclamation-circle-fill v-if="project.isExpired && project.state === 'active'" :id="`popover-${project.projectId}-expired`" variant="danger" class="mr-1"/>
              <b-popover v-if="project.isExpired && project.state === 'active'" :target="`popover-${project.projectId}-expired`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.projects.expiredicon.title') }}</template>
                {{ $t('comp.projects.expiredicon.text') }}
              </b-popover>
              <b-icon-journal-check class="mr-2"/> <b>{{ project.projectId }}</b> : {{ project.title }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`project-${project.projectId}`" accordion="projects" role="tabpanel" @show="project.loadMe = true" :visible="project.loadMe">
            <b-card-body>
              <b-card-text>
                <b-card-group deck>
                  <b-card class="mt-2">
                    <template #header>
                      <b-icon-info-circle/> {{ $t('comp.projects.details.label') }}
                    </template>
                    <b-card-text>
                      <ProjectInfo :project="project" view="manager"/>
                      <ProjectUsageInfo v-if="project.loadMe" :project="project.projectId" view="manager"/>
                    </b-card-text>
                  </b-card>
                  <b-card class="mt-2" v-if="false"> <!-- don't show requests for the moment -->
                    <template #header>
                      <b-icon-file-earmark-text/> {{ $t('comp.projects.requests.label') }}
                    </template>
                    <b-card-text>
                      <Requests :project-id="project.projectId"/>
                    </b-card-text>
                  </b-card>
                  <b-card class="mt-2">
                    <template #header>
                      <b-icon-person-circle/> {{ $t('comp.projects.accounts.label') }}
                    </template>
                    <b-card-text>
                      <Accounts v-if="project.loadMe" :project-id="project.projectId"/>
                    </b-card-text>
                  </b-card>
                </b-card-group>
                <b-card-group class="mt-3" deck>
                  <b-card class="mt-2">
                    <template #header>
                      <b-icon-file-earmark-text/> {{ $t('comp.projects.invitations.label') }}
                      <b-iconstack v-if="!showVouchers" class="float-right" font-scale="1.5" @click="openVouchers()">
                        <b-icon stacked icon="circle"></b-icon>
                        <b-icon stacked scale="0.75" icon="tags"></b-icon>
                      </b-iconstack>
                    </template>
                    <b-card-text>
                      <Invitations v-if="project.loadMe" :project="project"/>
                    </b-card-text>
                  </b-card>
                  <b-card class="mt-2" v-if="showVouchers">
                    <template #header>
                      <b-icon-file-earmark-text/> {{ $t('comp.projects.vouchers.label') }}
                      <b-iconstack class="float-right" font-scale="1.5" @click="closeVouchers()">
                        <b-icon stacked icon="circle"></b-icon>
                        <b-icon stacked scale="0.75" icon="tags-fill"></b-icon>
                      </b-iconstack>
                    </template>
                    <b-card-text>
                      <Vouchers v-if="project.loadMe" :project="project"/>
                    </b-card-text>
                  </b-card>
                </b-card-group>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div v-else-if="projects.length === 0 && projectFilter.length !== 0">
      <b-alert variant="info" show class="mx-3">{{ $t('comp.projects.noProjectsFound.description') }}</b-alert>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">{{ $t('comp.projects.noProject.description') }}</b-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { i18nMixin } from '@/mixins/i18n.mixin'
import Requests from '@/components/manager/Requests'
import Accounts from '@/components/manager/Accounts'
import ProjectInfo from '@/components/generic/info/ProjectInfo'
import Invitations from '@/components/manager/Invitations'
import Vouchers from '@/components/manager/Vouchers'
import ProjectUsageInfo from '@/components/generic/info/ProjectUsageInfo'

export default {
  name: 'Projects',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Invitations,
    ProjectInfo,
    Requests,
    Accounts,
    Vouchers,
    ProjectUsageInfo
  },
  data () {
    return {
      showVouchers: false,
      projectFilter: '',
      projectsFiltered: false
    }
  },
  watch: {
    projectFilter (val) {
      if (val.length >= 3) {
        this.getProjects(val)
        this.projectsFiltered = true
      } else if (val.length === 0 && this.projectsFiltered === true) {
        this.getProjects()
        this.projectsFiltered = false
      }
    }
  },
  computed: mapState({
    projects: state => state.manager.projects
  }),
  created () {
    this.getProjects()
  },
  methods: {
    getProjects (filter = '') {
      return this.$store.dispatch({ type: 'manager/getProjects', filter: filter })
    },
    reloadProjects () {
      // Setting filter to empty will trigger reload: Force only if filter not set
      this.projectFilter = ''
      if (this.projectsFiltered === false) this.getProjects()
    },
    openVouchers () {
      this.showVouchers = true
    },
    closeVouchers () {
      this.showVouchers = false
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
/* https://stackoverflow.com/questions/36487389/bootstrap-4-card-deck-table-cell-columns-responsive-based-on-viewport */
.card-deck {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(475px, 1fr));
    grid-gap: 0rem;
}
</style>
