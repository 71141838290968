<template>
  <div id="requestsTab">
    <div v-if="requests.length > 0">
      <div class="accordion" role="tablist">
        <b-card v-for="request in requests" :key="request.requestId" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`request-${request.requestId}`" variant="light" class="text-left">
              <b-icon-file-earmark-text/> {{ $t('comp.requeststab.requestForUser.label', {
              request: request.requestId,
              user: request.forUser.label
            }) }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`request-${request.requestId}`" accordion="requests" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <Request :request="request" view="manager"/>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">
        <b-icon-info-circle/> {{ $t('comp.requeststab.noRequest.description') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Request from '@/components/manager/Request'

export default {
  name: 'RequestsTab',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Request
  },
  props: {
    requests: {
      type: Array,
      required: true
    },
    projectId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
