<template>
  <div id="invitation">
    <b-button v-if="isStatePending && enableResend" :disabled="resendDisabled" variant="primary" @click.once="resendInvitation()" class="mb-3 ml-3">
      <b-icon-arrow-clockwise/> {{ $t('comp.invitation.resend.label') }}
    </b-button>
    <b-button v-if="isStatePending" variant="danger" @click="deleteInvitation()" class="mb-3 ml-3">
      <b-icon-trash/> {{ $t('comp.invitation.delete.label') }}
    </b-button>

    <p v-if="resendDisabled">
      {{ $t('comp.invitation.resend.success') }}
    </p>

    <InvitationInfo :invitation="invitation" :view="view"/>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import InvitationInfo from '@/components/manager/InvitationInfo'

export default {
  name: 'Invitation',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    InvitationInfo
  },
  props: {
    invitation: {
      type: Object,
      required: true
    },
    enableResend: {
      type: Boolean,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  beforeMount () {
  },
  computed: {
    isStatePending () {
      return this.invitation.state === 'pending'
    },
    resendDisabled () {
      return this.$store.getters['manager/isResendDisabledForInvitation'](this.invitation.invitationId)
    }
  },
  methods: {
    resendInvitation () {
      this.$store.dispatch({ type: 'manager/resendMail', invitationId: this.invitation.invitationId }).then(
        response => {
          this.makeToast(
            this.$i18n.t('resend.text', { id: this.invitation.invitationId, invitee: response.invitee }),
            this.$i18n.t('resend.title'),
            'success'
          )
          this.$store.dispatch({ type: 'manager/disableResendForInvitation', invitationId: this.invitation.invitationId })
        }
      ).catch(
        error => {
          this.makeToast(
            this.$i18n.t('error.text', { id: this.invitation.invitationId, status: error.status, message: 'Internal Server Error: Mail could not be sent!' }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        }
      )
    },
    deleteInvitation () {
      this.$store.dispatch({ type: 'manager/deleteInvitation', id: this.invitation.invitationId, project: this.invitation.forProject.key }).then(
        response => {
          this.makeToast(
            this.$i18n.t('delete.text', { id: this.invitation.invitationId }),
            this.$i18n.t('delete.title'),
            'success'
          )
        }
      ).catch(
        error => {
          this.makeToast(
            this.$i18n.t('error.text', { id: this.invitation.invitationId, status: error.status, message: 'Internal Server Error: Invitation could not be deleted!' }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        }
      )
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
