<template>
  <div id="accountsTab">
    <div v-if="accounts.length > 0">
      <div class="accordion" role="tablist">
        <b-card v-for="account in accounts" :key="account.username" no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="`account-${account.username}-${projectId}`" variant="light" class="text-left">
              <b-icon-info-circle-fill v-if="account.willExpire && account.state === 'active'" :id="`popover-${account.username}-expiry`" variant="warning" class="mr-1"/>
              <b-popover v-if="account.willExpire && account.state === 'active'" :target="`popover-${account.username}-expiry`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.accountstab.expiryicon.title') }}</template>
                {{ $t('comp.accountstab.expiryicon.text') }}
              </b-popover>
              <b-icon-exclamation-circle-fill v-if="account.isExpired && account.state === 'active'" :id="`popover-${account.username}-expired`" variant="danger" class="mr-1"/>
              <b-popover v-if="account.isExpired && account.state === 'active'" :target="`popover-${account.username}-expired`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.accountstab.expiredicon.title') }}</template>
                {{ $t('comp.accountstab.expiredicon.text') }}
              </b-popover>
              <b-iconstack v-if="!account.publicKeysFound && account.state === 'active'" :id="`popover-${account.username}-publickey`" class="mr-1">
                <b-icon stacked icon="circle-fill" variant="danger"/>
                <b-icon stacked icon="key-fill" scale="0.75" variant="light"/>
              </b-iconstack>
              <b-popover v-if="!account.publicKeysFound && account.state === 'active'" :target="`popover-${account.username}-publickey`" triggers="hover" placement="top">
                <template #title>{{ $t('comp.accountstab.keyicon.title') }}</template>
                {{ $t('comp.accountstab.keyicon.text') }}
              </b-popover>
              <b-icon-person-circle/> {{ $t('comp.accountstab.accountForUser.label', {
              account: account.username,
              user: account.user ? account.user.label : 'TBD'
            }) }}
              <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
              <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
            </b-button>
          </b-card-header>
          <b-collapse :id="`account-${account.username}-${projectId}`" accordion="accounts" role="tabpanel" @show="account.loadUsages = true" :visible="account.loadUsages">
            <b-card-body>
              <b-card-text>
                <Account v-if="account.loadUsages" :project-id="projectId" :account="account" view="manager"/>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div v-else-if="accounts.length === 0 && filtered">
      <b-alert variant="info" show class="mx-3">
        <b-icon-info-circle/> {{ $t('comp.accountstab.noAccountsFound.description') }}
      </b-alert>
    </div>
    <div v-else>
      <b-alert variant="info" show class="mx-3">
        <b-icon-info-circle/> {{ $t('comp.accountstab.noAccount.description') }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import Account from '@/components/manager/Account'

export default {
  name: 'AccountsTab',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    Account
  },
  props: {
    accounts: {
      type: Array,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    filtered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
