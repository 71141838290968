<template>
  <div id="vouchers">
    <div class="row justify-content-md-start mx-0 mb-3">
      <div :class="`col-12 mb-3 col-md-${hasVoucherCodes ? 3 : 4} mb-md-0 pl-0 pr-2`">
        <b-form-input size="sm" class="w-100" v-model="voucherFilter" type="search" placeholder="Filter ..."/>
      </div>
      <div v-if="project.isExpired || disableNewVoucher" class="col-6 col-md-auto pl-0 pr-2">
        <b-button variant="success" class="w-100" size="sm" :title="$t('comp.vouchers.disabled.title')" disabled>
          <b-icon-plus/> {{ $t('comp.vouchers.disabled.label') }}
        </b-button>
      </div>
      <div v-else :class="`col-6 col-md-${hasVoucherCodes ? 3 : 'auto'} pl-0 pr-2`">
        <b-button variant="success" class="w-100" size="sm" v-b-modal="`voucher-add-${project.projectId}`" @click="add(project)">
          <b-icon-plus/> {{ $t('comp.vouchers.add.label') }}
        </b-button>
      </div>
      <div :class="`col-6 col-md-${hasVoucherCodes ? 3 : 'auto'} pl-0 pr-2`">
        <b-button variant="primary" @click="reloadVouchers()" class="w-100" size="sm">
          <b-icon-arrow-repeat/> {{ $t('comp.vouchers.refresh.label') }}
        </b-button>
      </div>
      <div v-if="hasVoucherCodes" class="col-12 col-md-3 pl-0 pr-2">
        <b-button variant="primary" v-b-modal="`codes-show-${project.projectId}`"  class="w-100 mt-3 mt-md-0" size="sm">
          <b-icon-file-earmark-text/> {{ $t('comp.vouchers.showcodes.label') }}
        </b-button>
      </div>
    </div>
    <b-modal :id="`voucher-add-${project.projectId}`" :title="$t('comp.vouchers.add.label')" size="xl" @hidden="resetForm">
      <div>
        <b-form v-if="voucherForm.showForm">
          <VoucherForm :voucher="voucherForm" action="add" view="manager"/>
        </b-form>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">
          {{ $t('cancel.label') }}
        </b-button>
        <b-button @click="onSubmit" variant="primary">
          <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal :id="`codes-show-${project.projectId}`" :title="$t('comp.vouchers.showcodes.label')" size="xl" ok-only scrollable>
      <div v-for="(code, index) in this.latestVoucherCodes" :key="code">
        <hr v-if="index !== 0"/>
        <p>
          {{ $t('comp.vouchers.showcodes.text', {
            project: project.title
          }) }}
        </p>
        <p style="font-size: 20px;">
          <b>{{ code }}</b>
        </p>
      </div>
    </b-modal>

    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.vouchers.pending.label') + ` (${vouchersPending.length})`" active>
        <VouchersTab :project-id="project.projectId" :vouchers="vouchersPending" :filtered="vouchersFiltered"/>
      </b-tab>
      <b-tab :title="$t('comp.vouchers.archived.label') + ` (${vouchersArchived.length})`">
        <VouchersTab :project-id="project.projectId" :vouchers="vouchersArchived" :filtered="vouchersFiltered" is-archive/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import VouchersTab from '@/components/manager/VouchersTab'
import VoucherForm from '@/components/generic/form/VoucherForm'
import _ from 'lodash'

export default {
  name: 'Vouchers',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    VoucherForm,
    VouchersTab
  },
  props: {
    project: {
      type: Object
    }
  },
  data () {
    return {
      action: '',
      voucherFilter: '',
      vouchersFiltered: false,
      voucherForm: {
        form: null,
        showDateTimeDecadeNav: true,
        showForm: false,
        generateBulk: false,
        emptyForm: {
          voucherId: '',
          forCode: '',
          forValidity: null,
          requestedVouchers: 1,
          forProject: {},
          state: 'pending'
        }
      }
    }
  },
  computed: {
    vouchersPending () {
      return this.$store.getters['manager/vouchersPendingForProject'](this.project.projectId)
    },
    vouchersArchived () {
      return this.$store.getters['manager/vouchersArchivedForProject'](this.project.projectId)
    },
    latestVoucherCodes () {
      return this.$store.getters['manager/latestVoucherCodesForProject'](this.project.projectId)
    },
    hasVoucherCodes () {
      if (!_.isEmpty(this.latestVoucherCodes)) {
        return true
      } else {
        return false
      }
    },
    disableNewVoucher () {
      if (this.project.state === 'inactive' || this.project.state === 'completed' || this.project.state === 'expired' || this.project.state === 'withdrawn') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    voucherFilter (val) {
      if (val.length >= 3) {
        this.$store.dispatch({ type: 'manager/getVouchersForProject', projectId: this.project.projectId, filter: val })
        this.vouchersFiltered = true
      } else if (val.length === 0 && this.vouchersFiltered === true) {
        this.$store.dispatch({ type: 'manager/getVouchersForProject', projectId: this.project.projectId })
        this.vouchersFiltered = false
      }
    }
  },
  created () {
    this.$store.dispatch({ type: 'manager/getVouchersForProject', projectId: this.projectId })
  },
  methods: {
    reloadVouchers () {
      // Setting filter to empty will trigger reload: Force only if filter not set
      this.voucherFilter = ''
      if (this.vouchersFiltered === false) this.$store.dispatch({ type: 'manager/getVouchersForProject', projectId: this.project.projectId })
    },
    resetForm () {
      this.action = ''
      this.voucherForm.generateBulk = false
      this.voucherForm.form = null
      this.voucherForm.showForm = false
    },
    add (project) {
      this.voucherFilter = ''
      this.action = 'add'
      this.voucherForm.form = _.cloneDeep(this.voucherForm.emptyForm)
      this.voucherForm.form.forProject = { key: project.projectId, label: project.title }
      this.voucherForm.form.forValidity = project.validUntil ? new Date(Date.parse(project.validUntil)) : new Date(Date.parse(project.validUntilAuto))
      this.voucherForm.showForm = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.action === 'add' && this.voucherForm.generateBulk === false) {
        this.$store.dispatch({
          type: 'manager/addVoucher',
          voucher: this.voucherForm.form
        }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: response.voucherId, code: response.code }),
              this.$i18n.t('comp.vouchers.created.title'),
              'success'
            )
            this.$bvModal.hide(`voucher-add-${this.voucherForm.form.forProject.key}`)
          }
        ).catch(
          error => {
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      } else if (this.action === 'add' && this.voucherForm.generateBulk === true) {
        this.$store.dispatch({
          type: 'manager/addBulkVoucher',
          voucher: this.voucherForm.form
        }).then(
          response => {
            const ids = response.map(function (element) { return ' ' + element.voucherId })
            this.makeToast(
              this.$i18n.t('created.text', { id: ids, code: response.code }),
              this.$i18n.t('comp.vouchers.created.title'),
              'success'
            )
            this.$bvModal.hide(`voucher-add-${this.voucherForm.form.forProject.key}`)
          }
        ).catch(error => {
          if (error.status === 500) {
            const parsed = JSON.parse(error.request.response)
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: parsed.error, id: parsed.id }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          } else {
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        })
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
