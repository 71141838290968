<template>
  <div class="management" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t("comp.management.title") }}</h1>
    <b-card-group v-if="hasRoleManager" deck class="m-3">
      <b-card>
        <template #header>
          <b-icon-journal-check/> {{ $t('comp.management.projects.label') }}
        </template>
        <Projects/>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import hasRoleMixin from '@/mixins/hasRole.mixin'
import Projects from '@/components/manager/Projects'

export default {
  name: 'Management',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, hasRoleMixin],
  components: {
    Projects
  },
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return `${this.$i18n.t('management.title')} | ${this.$i18n.t('app.title')}`
      }
      return null
    }
  }
}
</script>
