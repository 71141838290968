<template>
  <div id="requests">
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.requests.pending.label') + ` (${requestsPending.length})`" active>
        <RequestsTab :project-id="projectId" :requests="requestsPending"/>
      </b-tab>
      <b-tab :title="$t('comp.requests.pendingWithFollowUp.label') + ` (${requestsPendingWithFollowUp.length})`">
        <RequestsTab :project-id="projectId" :requests="requestsPendingWithFollowUp"/>
      </b-tab>
      <b-tab :title="$t('comp.requests.archived.label') + ` (${requestsArchived.length})`">
        <RequestsTab :project-id="projectId" :requests="requestsArchived"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import RequestsTab from '@/components/manager/RequestsTab'

export default {
  name: 'Requests',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    RequestsTab
  },
  props: {
    projectId: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  computed: {
    requestsPending () {
      return this.$store.getters['manager/requestsPendingForProject'](this.projectId)
    },
    requestsPendingWithFollowUp () {
      return this.$store.getters['manager/requestsPendingWithFollowUpForProject'](this.projectId)
    },
    requestsArchived () {
      return this.$store.getters['manager/requestsArchivedForProject'](this.projectId)
    }
  },
  created () {
    this.$store.dispatch({ type: 'manager/getRequestsForProject', projectId: this.projectId })
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
