<template>
  <div id="account">
    <AccountInfo :account="account" :view="view"/>
    <b-button variant="primary" v-b-modal="`account-${account.username}-${projectId}`" @click="editAccount(account)" class="ml-4">
      <b-icon-pencil/> {{ $t('comp.accounts.edit.label') }}
    </b-button>
    <AccountUsageInfo :account="account.username" :for-project="this.projectId" :view="view"/>
    <b-modal :id="`account-${account.username}-${projectId}`" :title="$t('comp.account.edit.header', { account: this.account.username })" size="xl" @hidden="resetForm">
      <b-form v-if="accountForm.showForm">
        <AccountForm :account="accountForm" action="edit" :view="view"/>
        <CommentCard :targetId="account.username" :new-comment.sync="newComment" :showTable="accountForm.showForm" view="manager"/>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">
          {{ $t('cancel.label') }}
        </b-button>
        <b-button @click="onSubmit" variant="primary">
          <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import CommentCard from '@/components/generic/helper/CommentCard'
import AccountInfo from '@/components/generic/info/AccountInfo'
import AccountForm from '@/components/generic/form/AccountForm'
import AccountUsageInfo from '@/components/generic/info/AccountUsageInfo'

export default {
  name: 'Account',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    CommentCard,
    AccountInfo,
    AccountForm,
    AccountUsageInfo
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    projectId: {
      type: String
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      newComment: '',
      changes: [],
      accountForm: {
        form: null,
        oldData: null,
        showForm: false,
        showDateTimeDecadeNav: true,
        emptyForm: {
          username: '',
          homeDirectory: '',
          shell: '/bin/bash',
          uid: '',
          project: null,
          user: null,
          publicKeys: [],
          state: 'active',
          createdAt: null,
          validUntil: null
        }
      }
    }
  },
  methods: {
    resetForm () {
      this.accountForm.form = null
      this.accountForm.oldData = null
      this.accountForm.showForm = false
      this.newComment = ''
      this.changes = []
    },
    editAccount (account) {
      account.validUntil = account?.validUntil ? new Date(account.validUntil) : null // Format to JSDate for display
      this.accountForm.form = _.cloneDeep(account)
      this.accountForm.oldData = _.cloneDeep(account)
      this.accountForm.showForm = true
      this.newComment = ''
      this.changes = []
    },
    onSubmit (evt) {
      evt.preventDefault()
      this.$store.dispatch({ type: 'manager/updateAccount', account: this.accountForm.form }).then(
        response => {
          this.logChanges(response.username)
          this.makeToast(
            this.$i18n.t('updated.text', { id: response.username, code: response.code }),
            this.$i18n.t('comp.account.updated.title'),
            'success'
          )
          this.$bvModal.hide(`account-${response.username}-${response.project.key}`)
        }
      ).catch(
        error => {
          const parsed = JSON.parse(error.request.response)
          this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: parsed.error, id: this.accountForm.form.username }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    logChanges (targetId) {
      Object.keys(this.accountForm.form).forEach(key => {
        if (!_.isEqual(this.accountForm.form[key], this.accountForm.oldData[key]) && key !== 'createdAt') { // ignore createdAt
          const newChange = { field: key, before: this.accountForm.oldData[key], after: this.accountForm.form[key] }
          this.changes.push(newChange)
        }
      })
      // Log Changes if any changes
      if (this.changes.length > 0 || this.newComment !== '') {
        this.$store.dispatch({ type: 'manager/addComment', targetId: targetId, comment: this.newComment, changes: this.changes }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: 'Log: ' + response.comment, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.title }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      }
    }
  }
}
</script>

<style scoped>
</style>
