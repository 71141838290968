<template>
  <div id="accounts">
    <div class="row justify-content-md-start mx-0 mb-3">
      <div class="col-6 col-xl-3 pl-0 pr-1 mb-1 mb-xl-0">
        <b-form-input size="sm" class="w-100" v-model="accountFilter" type="search" placeholder="Filter ..."/>
      </div>
      <div class="col-6 col-xl-3 pl-1 pr-0 pr-xl-1 mb-1 mb-xl-0">
        <b-button @click="reloadAccounts()" variant="primary" class="w-100" size="sm">
          <b-icon-arrow-repeat/> {{ $t('comp.accounts.refreshButton.label') }}
        </b-button>
      </div>
      <div class="col-6 col-xl-3 pl-0 pl-xl-1 pr-1">
        <b-button @click="sortBy('username')" variant="secondary" class="w-100" size="sm">
          <b-icon-arrow-up-circle       class="mr-1" v-if="sortByAccountState === false && sortDescending === false"/>
          <b-icon-arrow-up-circle-fill  class="mr-1" v-if="sortByAccountState === true && sortDescending === false"/>
          <b-icon-arrow-down-circle       class="mr-1" v-if="sortByAccountState === false && sortDescending === true"/>
          <b-icon-arrow-down-circle-fill  class="mr-1" v-if="sortByAccountState === true && sortDescending === true"/>
          {{ $t('comp.accounts.sortbyaccount.label') }}
        </b-button>
      </div>
      <div class="col-6 col-xl-3 pl-1 pr-0">
        <b-button @click="sortBy('user.label')" variant="secondary" class="w-100" size="sm">
          <b-icon-arrow-up-circle       class="mr-1" v-if="sortByNameState === false && sortDescending === false"/>
          <b-icon-arrow-up-circle-fill  class="mr-1" v-if="sortByNameState === true && sortDescending === false"/>
          <b-icon-arrow-down-circle       class="mr-1" v-if="sortByNameState === false && sortDescending === true"/>
          <b-icon-arrow-down-circle-fill  class="mr-1" v-if="sortByNameState === true && sortDescending === true"/>
          {{ $t('comp.accounts.sortbyname.label') }}
        </b-button>
      </div>
    </div>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.accounts.active.label') + ` (${accountsActive.length})`" active>
        <AccountsTab :project-id="projectId" :accounts="accountsActive" :filtered="accountsFiltered"/>
      </b-tab>
      <b-tab :title="$t('comp.accounts.archived.label') + ` (${accountsArchived.length})`">
        <AccountsTab :project-id="projectId" :accounts="accountsArchived" :filtered="accountsFiltered"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import AccountsTab from '@/components/manager/AccountsTab'

export default {
  name: 'Accounts',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    AccountsTab
  },
  props: {
    projectId: {
      type: String
    }
  },
  data () {
    return {
      accountFilter: '',
      accountsFiltered: false,
      sortByField: 'username',
      sortDescending: true,
      sortByAccountState: true,
      sortByNameState: false
    }
  },
  watch: {
    accountFilter (val) {
      if (val.length >= 3) {
        this.$store.dispatch({ type: 'manager/getAccountsForProject', projectId: this.projectId, sortBy: this.sortByField, sortDesc: this.sortDescending, filter: val })
        this.accountsFiltered = true
      } else if (val.length === 0 && this.accountsFiltered === true) {
        this.$store.dispatch({ type: 'manager/getAccountsForProject', projectId: this.projectId, sortBy: this.sortByField, sortDesc: this.sortDescending })
        this.accountsFiltered = false
      }
    }
  },
  computed: {
    accountsActive () {
      return this.$store.getters['manager/accountsActiveForProject'](this.projectId)
    },
    accountsArchived () {
      return this.$store.getters['manager/accountsArchivedForProject'](this.projectId)
    }
  },
  created () {
    this.$store.dispatch({ type: 'manager/getAccountsForProject', projectId: this.projectId, sortBy: this.sortByField, sortDesc: this.sortDescending })
  },
  methods: {
    reloadAccounts () {
      // Setting filter to empty will trigger reload: Force only if filter not set
      this.accountFilter = ''
      if (this.accountsFiltered === false) this.$store.dispatch({ type: 'manager/getAccountsForProject', projectId: this.projectId, sortBy: this.sortByField, sortDesc: this.sortDescending })
    },
    sortBy (field) {
      if (field === 'username') {
        if (this.sortByAccountState === false && this.sortByNameState === true) {
          this.sortByAccountState = true
          this.sortByNameState = false
          this.sortByField = field
        } else {
          this.sortDescending = !this.sortDescending
        }
      } else if (field === 'user.label') {
        if (this.sortByAccountState === true && this.sortByNameState === false) {
          this.sortByAccountState = false
          this.sortByNameState = true
          this.sortByField = field
        } else {
          this.sortDescending = !this.sortDescending
        }
      } else {
        console.log('Unknown field to sort by!')
      }
      this.$store.dispatch({ type: 'manager/getAccountsForProject', projectId: this.projectId, sortBy: this.sortByField, filter: this.accountFilter, sortDesc: this.sortDescending })
    }
  }
}
</script>

<style scoped>
</style>
